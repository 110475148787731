import { FC } from "react"

import { ReactComponent as LogoImage } from "../../assets/icons/Logo.svg"

import "./index.less"

export enum LogoTheme {
  WHITE = "white",
  BLACK = "black",
  BLUE = 'blue'
}

interface LogoProps {
  logoTheme?: LogoTheme
}
const Logo: FC<LogoProps> = ({ logoTheme = LogoTheme.WHITE }) => {
  return <LogoImage className={`logo-${logoTheme}`} />
}

export { Logo }
