import { Flex, Menu, MenuProps } from "antd"
import { FC } from "react"
import "./index.less"
import { ReactComponent as PhoneImage } from '../../assets/icons/Phone.svg';

const Navigation: FC<MenuProps> = ({ theme = "light", ...props }) => {
  return <Flex className={"centered-menu"}>
    <Menu className={"centered-menu"} theme={theme} {...props} />
    <div className={"menu-extra-info"}>
      <div className={"content"}>
        <PhoneImage className={"phone"} />
        <div className={"working-info"}>
          <span className="phone-number">
            +65 6646 3448
          </span>
          <span className="working-time">
            (Weekdays 10am to 6pm)
          </span>
        </div>
      </div>
    </div>
  </Flex >
}

export default Navigation
