import { Button, Col, Divider, Image, Row, Space, Typography } from "antd"
import { FC } from "react"
import { NavLink } from "react-router-dom"

import { Logo } from "../../../components/logo"
import Top100Apac2024Image from "../../../assets/icons/Top100Apac2024.svg"
import E27WhiteLogoImage from "../../../assets/icons/E27WhiteLogo.svg"
import TectInAsiaWhiteImage from "../../../assets/icons/TechInAsiaWhiteLogo.svg"

import "./Footer.less"

import { useFooterLinkGroupsQuery } from "../../../graphql"

const Footer: FC<{ rights: string | null | undefined, policies: LinkFragment[] }> = ({ rights, policies }) => {
  const { data } = useFooterLinkGroupsQuery();
  const linkGroups = data?.footerLinkGroups?.data

  return (
    <Row justify={"space-between"} className={"footer-wrapper container"}>
      <Col>
        <Row className={"footer-sitemap-wrapper"}>
          <Col>
            <Row><NavLink to={"/"} aria-label="Homepage"><Logo /></NavLink></Row>
            <Row>
              <img loading="lazy" src={Top100Apac2024Image} style={{ maxWidth: "100px", width: "100%", height: "100%" }} alt="Top 100 Apac 2024" />
            </Row>
          </Col>
          <div className={'footer-sitemap-links'}>
            {linkGroups && !!linkGroups.length && linkGroups.map(linkGroup => (
              <Col key={linkGroup?.attributes?.groupName} className={"footer-link-group-wrapper"}>
                {linkGroup?.attributes?.url ? <Button href={linkGroup?.attributes?.url} className={"footer-link-group-name"}>{linkGroup?.attributes?.groupName}</Button> : <Typography.Title level={4} className={"footer-link-group-name"}>{linkGroup?.attributes?.groupName}</Typography.Title>}
                <div className={"footer-link-group"}>
                  {linkGroup?.attributes?.groupLink && !!linkGroup?.attributes?.groupLink.length && linkGroup?.attributes?.groupLink.map(link => (
                    link?.url.includes(";") && link?.url.split(";").length == 2 ?
                      <div key={link?.id}>
                        {link?.title}
                        <a href={link?.url.split(";")[0]} target={link?.target} rel={"nofollow"} aria-label="Tech In Asia"><Image loading={"lazy"} src={TectInAsiaWhiteImage} preview={false} alt="Tech in asia" /></a>
                        and
                        <a href={link?.url.split(";")[1]} target={link?.target} rel={"nofollow"} aria-label="E27"><Image loading={"lazy"} src={E27WhiteLogoImage} preview={false} alt="E27" /></a>
                      </div>
                      :
                      <Button key={link?.id} type={"text"} href={link?.url}>{link?.title}</Button>
                  ))}
                </div>
              </Col>
            ))}
          </div>
        </Row >
        <Divider />
        <Row align={"middle"} justify={"start"} className={"footer-rights-and-policies-wrapper"}>
          {rights && <Typography.Text>{rights}</Typography.Text>}
          <div className={"footer-policies-wrapper"}>
            {policies && !!policies.length && policies.map(policy => (
              <Button key={policy.id} type={"text"} href={policy.url}>{policy.title}</Button>
            ))}
          </div>
        </Row>
      </Col >
    </Row >
  )
}

export { Footer }
