import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "AboutUs",
      "AnnualRevenue",
      "Article",
      "BuyerButtonHero",
      "BuyerGuide",
      "BuyerHero",
      "ClosedDeal",
      "ComponentDataEntry",
      "ComponentDataSet",
      "ComponentUiCard",
      "ComponentUiComparisonCard",
      "ComponentUiFindYourIdealTarget",
      "ComponentUiFounderCompanies",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiMatchingSellersAndBuyers",
      "ComponentUiPara",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiSectionFounders",
      "ComponentUiSectionQa",
      "ComponentUiSectionWithCards",
      "ComponentUiSectionWithComparisonCards",
      "ComponentUiTab",
      "ComponentUiText",
      "ContactBuyer",
      "ContactSeller",
      "ContactUs",
      "ContactUsInfo",
      "Country",
      "DesiredEditda",
      "DesiredTransaction",
      "EditdaMargin",
      "FilterSorted",
      "FooterLinkGroup",
      "GuideTemplate",
      "Home",
      "IdealBuyer",
      "Industry",
      "InvestmentStyle",
      "Layout",
      "NumberOfEmployee",
      "Price",
      "Pricing",
      "PrivacyPolicy",
      "Profitability",
      "Property",
      "PropertyBuyer",
      "PropertyFavorite",
      "PropertyView",
      "ReactIconsIconlibrary",
      "RequestInvestmentProfile",
      "Resource",
      "RevenueRange",
      "Seller",
      "SellerGuide",
      "SellingReason",
      "SubSector",
      "TargetExitPeriod",
      "TermsOfUse",
      "Ticket",
      "TypeOfInvestor",
      "TypeOfTargetValuation",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "AboutUs",
      "AnnualRevenue",
      "Article",
      "BuyerButtonHero",
      "BuyerGuide",
      "BuyerHero",
      "ClosedDeal",
      "ComponentDataEntry",
      "ComponentDataSet",
      "ComponentUiCard",
      "ComponentUiComparisonCard",
      "ComponentUiFindYourIdealTarget",
      "ComponentUiFounderCompanies",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiMatchingSellersAndBuyers",
      "ComponentUiPara",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiSectionFounders",
      "ComponentUiSectionQa",
      "ComponentUiSectionWithCards",
      "ComponentUiSectionWithComparisonCards",
      "ComponentUiTab",
      "ComponentUiText",
      "ContactBuyer",
      "ContactSeller",
      "ContactUs",
      "ContactUsInfo",
      "Country",
      "DesiredEditda",
      "DesiredTransaction",
      "EditdaMargin",
      "FilterSorted",
      "FooterLinkGroup",
      "GuideTemplate",
      "Home",
      "IdealBuyer",
      "Industry",
      "InvestmentStyle",
      "Layout",
      "NumberOfEmployee",
      "Price",
      "Pricing",
      "PrivacyPolicy",
      "Profitability",
      "Property",
      "PropertyBuyer",
      "PropertyFavorite",
      "PropertyView",
      "ReactIconsIconlibrary",
      "RequestInvestmentProfile",
      "Resource",
      "RevenueRange",
      "Seller",
      "SellerGuide",
      "SellingReason",
      "SubSector",
      "TargetExitPeriod",
      "TermsOfUse",
      "Ticket",
      "TypeOfInvestor",
      "TypeOfTargetValuation",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ]
  }
};
      export default result;
    
export const EntryFragmentDoc = gql`
    fragment Entry on ComponentDataEntry {
  id
  key
  value
}
    `;
export const LinkFragmentDoc = gql`
    fragment Link on ComponentUiLink {
  id
  title
  url
  target
}
    `;
export const FooterLinkGroupFragmentDoc = gql`
    fragment FooterLinkGroup on FooterLinkGroup {
  groupName
  url
  groupLink {
    ...Link
  }
}
    `;
export const FoundersFragmentDoc = gql`
    fragment Founders on ComponentUiSectionFounders {
  name
  align
  description
  image {
    data {
      attributes {
        url
      }
    }
  }
  founderCompaniesImgs {
    companyLogo {
      data {
        attributes {
          url
        }
      }
    }
  }
}
    `;
export const HeadlineFragmentDoc = gql`
    fragment Headline on ComponentUiHeadline {
  id
  title
  subtitle
}
    `;
export const IdealTargetFragmentDoc = gql`
    fragment IdealTarget on ComponentUiFindYourIdealTarget {
  heading
  card1 {
    subtitle
    title
  }
  card2 {
    title
    subtitle
  }
  card3 {
    subtitle
    title
  }
}
    `;
export const IndustryFragmentDoc = gql`
    fragment Industry on Industry {
  option
}
    `;
export const MatchingSellersAndBuyersSectionFragmentDoc = gql`
    fragment MatchingSellersAndBuyersSection on ComponentUiMatchingSellersAndBuyers {
  description
  heading
  image {
    data {
      attributes {
        url
      }
    }
  }
  featuresImage {
    data {
      attributes {
        url
      }
    }
  }
}
    `;
export const ParagraphFragmentDoc = gql`
    fragment Paragraph on ComponentUiParagraph {
  id
  value
}
    `;
export const PriceFragmentDoc = gql`
    fragment Price on PriceEntityResponse {
  data {
    id
    attributes {
      option
    }
  }
}
    `;
export const PropertyFragmentDoc = gql`
    fragment Property on Property {
  companyName
  askingPrice {
    data {
      id
      attributes {
        option
      }
    }
  }
  industries {
    data {
      id
      attributes {
        option
      }
    }
  }
}
    `;
export const QaFragmentDoc = gql`
    fragment QA on ComponentUiSectionQa {
  label
  text
  textRich
}
    `;
export const ComparisonCardFragmentDoc = gql`
    fragment ComparisonCard on ComponentUiComparisonCard {
  criterion
  matchAsiaText
  matchAsiaIsIcon
  traditionalAdvisorText
  traditionalAdvisorIsIcon
  traditionalAdvisorIcon
}
    `;
export const SectionWithComparisonCardsFragmentDoc = gql`
    fragment SectionWithComparisonCards on ComponentUiSectionWithComparisonCards {
  title
  subtitle
  comparisonCards {
    ...ComparisonCard
  }
}
    `;
export const FileFragmentDoc = gql`
    fragment File on UploadFileEntity {
  id
  attributes {
    previewUrl
    alternativeText
    url
  }
}
    `;
export const CardFragmentDoc = gql`
    fragment Card on ComponentUiCard {
  id
  title
  subtitle
  description
  media {
    data {
      ...File
    }
  }
}
    `;
export const SectionFragmentDoc = gql`
    fragment Section on ComponentUiSection {
  id
  button {
    ...Link
  }
  heading {
    ...Card
  }
}
    `;
export const SectionWithCardsFragmentDoc = gql`
    fragment SectionWithCards on ComponentUiSectionWithCards {
  title
  card {
    ...Card
  }
}
    `;
export const TabFragmentDoc = gql`
    fragment Tab on ComponentUiTab {
  id
  name
  pane {
    ...Card
  }
}
    `;
export const ContactBuyerDocument = gql`
    mutation ContactBuyer($input: ContactBuyerInput!) {
  createContactBuyer(data: $input) {
    data {
      attributes {
        wonderingUserId
        propertyId
        companyName
      }
    }
  }
}
    `;
export type ContactBuyerMutationFn = Apollo.MutationFunction<ContactBuyerMutation, ContactBuyerMutationVariables>;
export function useContactBuyerMutation(baseOptions?: Apollo.MutationHookOptions<ContactBuyerMutation, ContactBuyerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ContactBuyerMutation, ContactBuyerMutationVariables>(ContactBuyerDocument, options);
      }
export type ContactBuyerMutationHookResult = ReturnType<typeof useContactBuyerMutation>;
export type ContactBuyerMutationResult = Apollo.MutationResult<ContactBuyerMutation>;
export const ContactSellerDocument = gql`
    mutation ContactSeller($input: ContactSellerInput!) {
  createContactSeller(data: $input) {
    data {
      attributes {
        wonderingUserId
        propertyId
        companyName
      }
    }
  }
}
    `;
export type ContactSellerMutationFn = Apollo.MutationFunction<ContactSellerMutation, ContactSellerMutationVariables>;
export function useContactSellerMutation(baseOptions?: Apollo.MutationHookOptions<ContactSellerMutation, ContactSellerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ContactSellerMutation, ContactSellerMutationVariables>(ContactSellerDocument, options);
      }
export type ContactSellerMutationHookResult = ReturnType<typeof useContactSellerMutation>;
export type ContactSellerMutationResult = Apollo.MutationResult<ContactSellerMutation>;
export const ContactUsDocument = gql`
    mutation ContactUs($data: ContactUsInput!) {
  createContactUs(data: $data) {
    data {
      attributes {
        name
        email
        message
        companyName
        questionAbout
      }
    }
  }
}
    `;
export type ContactUsMutationFn = Apollo.MutationFunction<ContactUsMutation, ContactUsMutationVariables>;
export function useContactUsMutation(baseOptions?: Apollo.MutationHookOptions<ContactUsMutation, ContactUsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ContactUsMutation, ContactUsMutationVariables>(ContactUsDocument, options);
      }
export type ContactUsMutationHookResult = ReturnType<typeof useContactUsMutation>;
export type ContactUsMutationResult = Apollo.MutationResult<ContactUsMutation>;
export const CreateBuyerDocument = gql`
    mutation CreateBuyer($input: PropertyBuyerInput!) {
  createPropertyBuyer(data: $input) {
    data {
      id
      attributes {
        companyName
        website
        description
        country {
          data {
            id
          }
        }
        industry {
          data {
            id
          }
        }
        typeOfInvestor {
          data {
            id
          }
        }
        ticketSize {
          data {
            id
          }
        }
        acquisitionPurposeInput
        revenueRange {
          data {
            id
          }
        }
        desiredEditda {
          data {
            id
          }
        }
        investmentStyle {
          data {
            id
          }
        }
        typeOfTargetValuation {
          data {
            id
          }
        }
        user {
          data {
            id
          }
        }
        otherInvestmentCriteriaInput
      }
    }
  }
}
    `;
export type CreateBuyerMutationFn = Apollo.MutationFunction<CreateBuyerMutation, CreateBuyerMutationVariables>;
export function useCreateBuyerMutation(baseOptions?: Apollo.MutationHookOptions<CreateBuyerMutation, CreateBuyerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBuyerMutation, CreateBuyerMutationVariables>(CreateBuyerDocument, options);
      }
export type CreateBuyerMutationHookResult = ReturnType<typeof useCreateBuyerMutation>;
export type CreateBuyerMutationResult = Apollo.MutationResult<CreateBuyerMutation>;
export const CreateSellerDocument = gql`
    mutation CreateSeller($input: PropertyInput!) {
  createProperty(data: $input) {
    data {
      id
      attributes {
        companyName
        website
        description
        country {
          data {
            id
          }
        }
        industries {
          data {
            id
          }
        }
        yearOfIncorporation
        numberOfEmployees {
          data {
            id
          }
        }
        productsAndServicesInput
        askingPrice {
          data {
            id
          }
        }
        idealBuyer {
          data {
            id
          }
        }
        desiredTransaction {
          data {
            id
          }
        }
        targetExitPeriod {
          data {
            id
          }
        }
        user {
          data {
            attributes {
              id
            }
          }
        }
      }
    }
  }
}
    `;
export type CreateSellerMutationFn = Apollo.MutationFunction<CreateSellerMutation, CreateSellerMutationVariables>;
export function useCreateSellerMutation(baseOptions?: Apollo.MutationHookOptions<CreateSellerMutation, CreateSellerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSellerMutation, CreateSellerMutationVariables>(CreateSellerDocument, options);
      }
export type CreateSellerMutationHookResult = ReturnType<typeof useCreateSellerMutation>;
export type CreateSellerMutationResult = Apollo.MutationResult<CreateSellerMutation>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email) {
    ok
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export const LoginDocument = gql`
    mutation login($input: UsersPermissionsLoginInput!) {
  login(input: $input) {
    jwt
    user {
      id
      username
      confirmed
      role {
        name
      }
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export const PropertyFavoriteDocument = gql`
    mutation PropertyFavorite($input: PropertyFavoriteInput!) {
  createPropertyFavorite(data: $input) {
    data {
      id
      attributes {
        propertyId {
          data {
            id
            attributes {
              companyName
            }
          }
        }
        source
      }
    }
  }
}
    `;
export type PropertyFavoriteMutationFn = Apollo.MutationFunction<PropertyFavoriteMutation, PropertyFavoriteMutationVariables>;
export function usePropertyFavoriteMutation(baseOptions?: Apollo.MutationHookOptions<PropertyFavoriteMutation, PropertyFavoriteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PropertyFavoriteMutation, PropertyFavoriteMutationVariables>(PropertyFavoriteDocument, options);
      }
export type PropertyFavoriteMutationHookResult = ReturnType<typeof usePropertyFavoriteMutation>;
export type PropertyFavoriteMutationResult = Apollo.MutationResult<PropertyFavoriteMutation>;
export const PropertyViewDocument = gql`
    mutation PropertyView($input: PropertyViewInput!) {
  createPropertyView(data: $input) {
    data {
      id
      attributes {
        propertyId
        source
      }
    }
  }
}
    `;
export type PropertyViewMutationFn = Apollo.MutationFunction<PropertyViewMutation, PropertyViewMutationVariables>;
export function usePropertyViewMutation(baseOptions?: Apollo.MutationHookOptions<PropertyViewMutation, PropertyViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PropertyViewMutation, PropertyViewMutationVariables>(PropertyViewDocument, options);
      }
export type PropertyViewMutationHookResult = ReturnType<typeof usePropertyViewMutation>;
export type PropertyViewMutationResult = Apollo.MutationResult<PropertyViewMutation>;
export const RegisterDocument = gql`
    mutation register($input: UsersPermissionsRegisterInput!) {
  register(input: $input) {
    jwt
    user {
      id
      role {
        id
        name
      }
      email
      username
    }
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export const RequestInvestmentProfileDocument = gql`
    mutation RequestInvestmentProfile($input: RequestInvestmentProfileInput!) {
  createRequestInvestmentProfile(data: $input) {
    data {
      attributes {
        companyName
        propertyId
        wonderingUserId
      }
    }
  }
}
    `;
export type RequestInvestmentProfileMutationFn = Apollo.MutationFunction<RequestInvestmentProfileMutation, RequestInvestmentProfileMutationVariables>;
export function useRequestInvestmentProfileMutation(baseOptions?: Apollo.MutationHookOptions<RequestInvestmentProfileMutation, RequestInvestmentProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestInvestmentProfileMutation, RequestInvestmentProfileMutationVariables>(RequestInvestmentProfileDocument, options);
      }
export type RequestInvestmentProfileMutationHookResult = ReturnType<typeof useRequestInvestmentProfileMutation>;
export type RequestInvestmentProfileMutationResult = Apollo.MutationResult<RequestInvestmentProfileMutation>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($code: String!, $password: String!, $passwordConfirmation: String!) {
  resetPassword(
    code: $code
    password: $password
    passwordConfirmation: $passwordConfirmation
  ) {
    jwt
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($data: UsersPermissionsUserInput!, $id: ID!) {
  updateUsersPermissionsUser(data: $data, id: $id) {
    data {
      id
      attributes {
        phone
        username
      }
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export const UpdateUserSettingsDocument = gql`
    mutation UpdateUserSettings($data: UsersPermissionsUserInput!, $id: ID!) {
  updateUsersPermissionsUser(data: $data, id: $id) {
    data {
      id
      attributes {
        phone
        property {
          data {
            id
          }
        }
        propertyBuyer {
          data {
            id
          }
        }
      }
    }
  }
}
    `;
export type UpdateUserSettingsMutationFn = Apollo.MutationFunction<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>;
export function useUpdateUserSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>(UpdateUserSettingsDocument, options);
      }
export type UpdateUserSettingsMutationHookResult = ReturnType<typeof useUpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationResult = Apollo.MutationResult<UpdateUserSettingsMutation>;
export const AboutUsDocument = gql`
    query AboutUs {
  aboutUs {
    data {
      attributes {
        foundersLeftText
        foundersRightText
        storyLeftText
        storyRightText
        foundersPhotoDesktop {
          data {
            attributes {
              url
            }
          }
        }
        foundersPhotoTablet {
          data {
            attributes {
              url
            }
          }
        }
        foundersPhotoMobile {
          data {
            attributes {
              url
            }
          }
        }
        whatLiveForImg {
          data {
            attributes {
              url
            }
          }
        }
        whatLiveForMobiImg {
          data {
            attributes {
              url
            }
          }
        }
        whatLiveForTitle
        whatLiveForSubtitle
        foundersPledgeImg {
          data {
            attributes {
              url
            }
          }
        }
        foundersPledgeText
        joinUsText
      }
    }
  }
}
    `;
export function useAboutUsQuery(baseOptions?: Apollo.QueryHookOptions<AboutUsQuery, AboutUsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AboutUsQuery, AboutUsQueryVariables>(AboutUsDocument, options);
      }
export function useAboutUsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AboutUsQuery, AboutUsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AboutUsQuery, AboutUsQueryVariables>(AboutUsDocument, options);
        }
export function useAboutUsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AboutUsQuery, AboutUsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AboutUsQuery, AboutUsQueryVariables>(AboutUsDocument, options);
        }
export type AboutUsQueryHookResult = ReturnType<typeof useAboutUsQuery>;
export type AboutUsLazyQueryHookResult = ReturnType<typeof useAboutUsLazyQuery>;
export type AboutUsSuspenseQueryHookResult = ReturnType<typeof useAboutUsSuspenseQuery>;
export type AboutUsQueryResult = Apollo.QueryResult<AboutUsQuery, AboutUsQueryVariables>;
export const BrowseBuyersDocument = gql`
    query browseBuyers($page: Int, $pageSize: Int, $industry: [ID], $ticket: [ID], $countries: [ID], $revenue: [ID], $investment: [ID]) {
  propertyBuyers(
    pagination: {page: $page, pageSize: $pageSize}
    sort: "id:desc"
    filters: {industry: {id: {in: $industry}}, ticketSize: {id: {in: $ticket}}, country: {id: {in: $countries}}, revenueRange: {id: {in: $revenue}}, investmentStyle: {id: {in: $investment}}}
  ) {
    data {
      id
      attributes {
        companyName
        description
        confirmed
        annualRevenue
        typeOfInvestor {
          data {
            id
            attributes {
              option
            }
          }
        }
        image {
          data {
            attributes {
              url
            }
          }
        }
        industry {
          data {
            attributes {
              option
            }
          }
        }
        country {
          data {
            attributes {
              option
            }
          }
        }
        ticketSize {
          data {
            id
            attributes {
              option
            }
          }
        }
        revenueRange {
          data {
            id
            attributes {
              option
            }
          }
        }
        investmentStyle {
          data {
            id
            attributes {
              option
            }
          }
        }
      }
    }
  }
}
    `;
export function useBrowseBuyersQuery(baseOptions?: Apollo.QueryHookOptions<BrowseBuyersQuery, BrowseBuyersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrowseBuyersQuery, BrowseBuyersQueryVariables>(BrowseBuyersDocument, options);
      }
export function useBrowseBuyersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrowseBuyersQuery, BrowseBuyersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrowseBuyersQuery, BrowseBuyersQueryVariables>(BrowseBuyersDocument, options);
        }
export function useBrowseBuyersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BrowseBuyersQuery, BrowseBuyersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BrowseBuyersQuery, BrowseBuyersQueryVariables>(BrowseBuyersDocument, options);
        }
export type BrowseBuyersQueryHookResult = ReturnType<typeof useBrowseBuyersQuery>;
export type BrowseBuyersLazyQueryHookResult = ReturnType<typeof useBrowseBuyersLazyQuery>;
export type BrowseBuyersSuspenseQueryHookResult = ReturnType<typeof useBrowseBuyersSuspenseQuery>;
export type BrowseBuyersQueryResult = Apollo.QueryResult<BrowseBuyersQuery, BrowseBuyersQueryVariables>;
export const BrowseBuyersBottomHeroDocument = gql`
    query BrowseBuyersBottomHero {
  buyerButtonHero {
    data {
      attributes {
        description
        title
        img {
          data {
            attributes {
              url
            }
          }
        }
        points
        subTitle
      }
    }
  }
}
    `;
export function useBrowseBuyersBottomHeroQuery(baseOptions?: Apollo.QueryHookOptions<BrowseBuyersBottomHeroQuery, BrowseBuyersBottomHeroQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrowseBuyersBottomHeroQuery, BrowseBuyersBottomHeroQueryVariables>(BrowseBuyersBottomHeroDocument, options);
      }
export function useBrowseBuyersBottomHeroLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrowseBuyersBottomHeroQuery, BrowseBuyersBottomHeroQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrowseBuyersBottomHeroQuery, BrowseBuyersBottomHeroQueryVariables>(BrowseBuyersBottomHeroDocument, options);
        }
export function useBrowseBuyersBottomHeroSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BrowseBuyersBottomHeroQuery, BrowseBuyersBottomHeroQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BrowseBuyersBottomHeroQuery, BrowseBuyersBottomHeroQueryVariables>(BrowseBuyersBottomHeroDocument, options);
        }
export type BrowseBuyersBottomHeroQueryHookResult = ReturnType<typeof useBrowseBuyersBottomHeroQuery>;
export type BrowseBuyersBottomHeroLazyQueryHookResult = ReturnType<typeof useBrowseBuyersBottomHeroLazyQuery>;
export type BrowseBuyersBottomHeroSuspenseQueryHookResult = ReturnType<typeof useBrowseBuyersBottomHeroSuspenseQuery>;
export type BrowseBuyersBottomHeroQueryResult = Apollo.QueryResult<BrowseBuyersBottomHeroQuery, BrowseBuyersBottomHeroQueryVariables>;
export const BrowseBuyersFiltersDocument = gql`
    query BrowseBuyersFilters {
  industries(sort: "option:asc") {
    data {
      id
      attributes {
        option
      }
    }
  }
  countries {
    data {
      id
      attributes {
        option
      }
    }
  }
  tickets {
    data {
      id
      attributes {
        option
      }
    }
  }
  revenueRanges(sort: "createdAt:asc") {
    data {
      id
      attributes {
        option
      }
    }
  }
  investmentStyles {
    data {
      id
      attributes {
        option
      }
    }
  }
  typeOfInvestors {
    data {
      id
      attributes {
        option
      }
    }
  }
  desiredEditdas {
    data {
      id
      attributes {
        option
      }
    }
  }
}
    `;
export function useBrowseBuyersFiltersQuery(baseOptions?: Apollo.QueryHookOptions<BrowseBuyersFiltersQuery, BrowseBuyersFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrowseBuyersFiltersQuery, BrowseBuyersFiltersQueryVariables>(BrowseBuyersFiltersDocument, options);
      }
export function useBrowseBuyersFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrowseBuyersFiltersQuery, BrowseBuyersFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrowseBuyersFiltersQuery, BrowseBuyersFiltersQueryVariables>(BrowseBuyersFiltersDocument, options);
        }
export function useBrowseBuyersFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BrowseBuyersFiltersQuery, BrowseBuyersFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BrowseBuyersFiltersQuery, BrowseBuyersFiltersQueryVariables>(BrowseBuyersFiltersDocument, options);
        }
export type BrowseBuyersFiltersQueryHookResult = ReturnType<typeof useBrowseBuyersFiltersQuery>;
export type BrowseBuyersFiltersLazyQueryHookResult = ReturnType<typeof useBrowseBuyersFiltersLazyQuery>;
export type BrowseBuyersFiltersSuspenseQueryHookResult = ReturnType<typeof useBrowseBuyersFiltersSuspenseQuery>;
export type BrowseBuyersFiltersQueryResult = Apollo.QueryResult<BrowseBuyersFiltersQuery, BrowseBuyersFiltersQueryVariables>;
export const BrowseBuyersHeroDocument = gql`
    query BrowseBuyersHero {
  buyerHero {
    data {
      attributes {
        img {
          data {
            attributes {
              url
            }
          }
        }
        buttonVisible
        buttonText
        subtitle
        title
        description
        imgTablet {
          data {
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
    `;
export function useBrowseBuyersHeroQuery(baseOptions?: Apollo.QueryHookOptions<BrowseBuyersHeroQuery, BrowseBuyersHeroQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrowseBuyersHeroQuery, BrowseBuyersHeroQueryVariables>(BrowseBuyersHeroDocument, options);
      }
export function useBrowseBuyersHeroLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrowseBuyersHeroQuery, BrowseBuyersHeroQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrowseBuyersHeroQuery, BrowseBuyersHeroQueryVariables>(BrowseBuyersHeroDocument, options);
        }
export function useBrowseBuyersHeroSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BrowseBuyersHeroQuery, BrowseBuyersHeroQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BrowseBuyersHeroQuery, BrowseBuyersHeroQueryVariables>(BrowseBuyersHeroDocument, options);
        }
export type BrowseBuyersHeroQueryHookResult = ReturnType<typeof useBrowseBuyersHeroQuery>;
export type BrowseBuyersHeroLazyQueryHookResult = ReturnType<typeof useBrowseBuyersHeroLazyQuery>;
export type BrowseBuyersHeroSuspenseQueryHookResult = ReturnType<typeof useBrowseBuyersHeroSuspenseQuery>;
export type BrowseBuyersHeroQueryResult = Apollo.QueryResult<BrowseBuyersHeroQuery, BrowseBuyersHeroQueryVariables>;
export const BrowseBuyersTotalCountDocument = gql`
    query browseBuyersTotalCount($industry: [ID], $ticket: [ID], $countries: [ID], $revenue: [ID], $investment: [ID]) {
  propertyBuyers(
    pagination: {limit: -1}
    filters: {industry: {id: {in: $industry}}, ticketSize: {id: {in: $ticket}}, country: {id: {in: $countries}}, revenueRange: {id: {in: $revenue}}, investmentStyle: {id: {in: $investment}}}
  ) {
    data {
      id
      attributes {
        companyName
        description
        confirmed
        annualRevenue
        image {
          data {
            attributes {
              url
            }
          }
        }
        industry {
          data {
            attributes {
              option
            }
          }
        }
        country {
          data {
            attributes {
              option
            }
          }
        }
        ticketSize {
          data {
            attributes {
              option
            }
          }
        }
        revenueRange {
          data {
            attributes {
              option
            }
          }
        }
        investmentStyle {
          data {
            attributes {
              option
            }
          }
        }
      }
    }
  }
}
    `;
export function useBrowseBuyersTotalCountQuery(baseOptions?: Apollo.QueryHookOptions<BrowseBuyersTotalCountQuery, BrowseBuyersTotalCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrowseBuyersTotalCountQuery, BrowseBuyersTotalCountQueryVariables>(BrowseBuyersTotalCountDocument, options);
      }
export function useBrowseBuyersTotalCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrowseBuyersTotalCountQuery, BrowseBuyersTotalCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrowseBuyersTotalCountQuery, BrowseBuyersTotalCountQueryVariables>(BrowseBuyersTotalCountDocument, options);
        }
export function useBrowseBuyersTotalCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BrowseBuyersTotalCountQuery, BrowseBuyersTotalCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BrowseBuyersTotalCountQuery, BrowseBuyersTotalCountQueryVariables>(BrowseBuyersTotalCountDocument, options);
        }
export type BrowseBuyersTotalCountQueryHookResult = ReturnType<typeof useBrowseBuyersTotalCountQuery>;
export type BrowseBuyersTotalCountLazyQueryHookResult = ReturnType<typeof useBrowseBuyersTotalCountLazyQuery>;
export type BrowseBuyersTotalCountSuspenseQueryHookResult = ReturnType<typeof useBrowseBuyersTotalCountSuspenseQuery>;
export type BrowseBuyersTotalCountQueryResult = Apollo.QueryResult<BrowseBuyersTotalCountQuery, BrowseBuyersTotalCountQueryVariables>;
export const BrowsePropertyFavoritesDocument = gql`
    query browsePropertyFavorites($page: Int, $pageSize: Int, $sourceFilters: [PropertyFavoriteFiltersInput]) {
  propertyFavorites(
    pagination: {page: $page, pageSize: $pageSize}
    sort: "id:desc"
    filters: {and: {or: $sourceFilters}}
  ) {
    data {
      id
      attributes {
        propertyId {
          data {
            id
            attributes {
              externalId
              companyName
            }
          }
        }
      }
    }
  }
}
    `;
export function useBrowsePropertyFavoritesQuery(baseOptions?: Apollo.QueryHookOptions<BrowsePropertyFavoritesQuery, BrowsePropertyFavoritesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrowsePropertyFavoritesQuery, BrowsePropertyFavoritesQueryVariables>(BrowsePropertyFavoritesDocument, options);
      }
export function useBrowsePropertyFavoritesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrowsePropertyFavoritesQuery, BrowsePropertyFavoritesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrowsePropertyFavoritesQuery, BrowsePropertyFavoritesQueryVariables>(BrowsePropertyFavoritesDocument, options);
        }
export function useBrowsePropertyFavoritesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BrowsePropertyFavoritesQuery, BrowsePropertyFavoritesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BrowsePropertyFavoritesQuery, BrowsePropertyFavoritesQueryVariables>(BrowsePropertyFavoritesDocument, options);
        }
export type BrowsePropertyFavoritesQueryHookResult = ReturnType<typeof useBrowsePropertyFavoritesQuery>;
export type BrowsePropertyFavoritesLazyQueryHookResult = ReturnType<typeof useBrowsePropertyFavoritesLazyQuery>;
export type BrowsePropertyFavoritesSuspenseQueryHookResult = ReturnType<typeof useBrowsePropertyFavoritesSuspenseQuery>;
export type BrowsePropertyFavoritesQueryResult = Apollo.QueryResult<BrowsePropertyFavoritesQuery, BrowsePropertyFavoritesQueryVariables>;
export const BrowseSellerFeaturedListingsDocument = gql`
    query browseSellerFeaturedListings($page: Int, $pageSize: Int, $sourceFilters: [PropertyFavoriteFiltersInput]) {
  properties(
    pagination: {page: $page, pageSize: $pageSize}
    sort: "featured:asc, createdAt:desc"
    filters: {featured: {eq: true}}
  ) {
    data {
      id
      attributes {
        companyName
        featured
        industries {
          data {
            attributes {
              option
            }
          }
        }
        externalId
        propertyFavorites(filters: {or: $sourceFilters}) {
          data {
            attributes {
              source
            }
          }
        }
      }
    }
  }
}
    `;
export function useBrowseSellerFeaturedListingsQuery(baseOptions?: Apollo.QueryHookOptions<BrowseSellerFeaturedListingsQuery, BrowseSellerFeaturedListingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrowseSellerFeaturedListingsQuery, BrowseSellerFeaturedListingsQueryVariables>(BrowseSellerFeaturedListingsDocument, options);
      }
export function useBrowseSellerFeaturedListingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrowseSellerFeaturedListingsQuery, BrowseSellerFeaturedListingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrowseSellerFeaturedListingsQuery, BrowseSellerFeaturedListingsQueryVariables>(BrowseSellerFeaturedListingsDocument, options);
        }
export function useBrowseSellerFeaturedListingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BrowseSellerFeaturedListingsQuery, BrowseSellerFeaturedListingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BrowseSellerFeaturedListingsQuery, BrowseSellerFeaturedListingsQueryVariables>(BrowseSellerFeaturedListingsDocument, options);
        }
export type BrowseSellerFeaturedListingsQueryHookResult = ReturnType<typeof useBrowseSellerFeaturedListingsQuery>;
export type BrowseSellerFeaturedListingsLazyQueryHookResult = ReturnType<typeof useBrowseSellerFeaturedListingsLazyQuery>;
export type BrowseSellerFeaturedListingsSuspenseQueryHookResult = ReturnType<typeof useBrowseSellerFeaturedListingsSuspenseQuery>;
export type BrowseSellerFeaturedListingsQueryResult = Apollo.QueryResult<BrowseSellerFeaturedListingsQuery, BrowseSellerFeaturedListingsQueryVariables>;
export const BrowseSellersDocument = gql`
    query browseSellers($page: Int, $pageSize: Int, $prices: [ID], $industriesAndSubSectors: [PropertyFiltersInput], $annualRevenues: [ID], $countries: [ID], $mainMarkets: [ID], $editdaMargins: [ID], $transactions: [ID], $searchBox: String, $tagSearch: [PropertyFiltersInput], $sourceInnerFilters: [PropertyFavoriteFiltersInput], $sourceLeftFilters: [PropertyFavoriteFiltersInput]) {
  properties(
    pagination: {page: $page, pageSize: $pageSize}
    sort: "createdAt:desc"
    filters: {and: {or: [{companyName: {containsi: $searchBox}}, {description: {containsi: $searchBox}}, {businessDescription: {containsi: $searchBox}}, {productsAndServices: {containsi: $searchBox}}, {highlightsUniqueSellingPoint: {containsi: $searchBox}}, {reasonForSale: {containsi: $searchBox}}, {externalId: {containsi: $searchBox}}, {or: $tagSearch}, {industries: {option: {containsi: $searchBox}}}, {subSectors: {option: {containsi: $searchBox}}}, {askingPrice: {option: {containsi: $searchBox}}}, {annualRevenue: {option: {containsi: $searchBox}}}, {country: {option: {containsi: $searchBox}}}, {mainMarket: {option: {containsi: $searchBox}}}, {margin: {option: {containsi: $searchBox}}}, {desiredTransaction: {option: {containsi: $searchBox}}}, {idealBuyer: {option: {containsi: $searchBox}}}, {mainMarket: {option: {containsi: $searchBox}}}, {profitability: {option: {containsi: $searchBox}}}, {margin: {option: {containsi: $searchBox}}}]}, askingPrice: {id: {in: $prices}}, annualRevenue: {id: {in: $annualRevenues}}, country: {id: {in: $countries}}, mainMarket: {id: {in: $mainMarkets}}, margin: {id: {in: $editdaMargins}}, desiredTransaction: {id: {in: $transactions}}, or: $industriesAndSubSectors, propertyFavorites: {or: $sourceInnerFilters}}
  ) {
    data {
      id
      attributes {
        companyName
        description
        confirmed
        yearOfIncorporation
        desiredTransaction {
          data {
            id
            attributes {
              option
            }
          }
        }
        featured
        profitability {
          data {
            attributes {
              option
            }
            id
          }
        }
        annualRevenue {
          data {
            id
            attributes {
              option
            }
          }
        }
        image {
          data {
            attributes {
              url
            }
          }
        }
        industries {
          data {
            attributes {
              option
            }
          }
        }
        subSectors {
          data {
            attributes {
              option
            }
          }
        }
        askingPrice {
          data {
            id
            attributes {
              option
            }
          }
        }
        margin {
          data {
            id
            attributes {
              option
            }
          }
        }
        country {
          data {
            attributes {
              option
            }
          }
        }
        desiredTransaction {
          data {
            attributes {
              option
            }
          }
        }
        externalId
        propertyFavorites(filters: {or: $sourceLeftFilters}) {
          data {
            attributes {
              source
            }
          }
        }
      }
    }
  }
}
    `;
export function useBrowseSellersQuery(baseOptions?: Apollo.QueryHookOptions<BrowseSellersQuery, BrowseSellersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrowseSellersQuery, BrowseSellersQueryVariables>(BrowseSellersDocument, options);
      }
export function useBrowseSellersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrowseSellersQuery, BrowseSellersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrowseSellersQuery, BrowseSellersQueryVariables>(BrowseSellersDocument, options);
        }
export function useBrowseSellersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BrowseSellersQuery, BrowseSellersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BrowseSellersQuery, BrowseSellersQueryVariables>(BrowseSellersDocument, options);
        }
export type BrowseSellersQueryHookResult = ReturnType<typeof useBrowseSellersQuery>;
export type BrowseSellersLazyQueryHookResult = ReturnType<typeof useBrowseSellersLazyQuery>;
export type BrowseSellersSuspenseQueryHookResult = ReturnType<typeof useBrowseSellersSuspenseQuery>;
export type BrowseSellersQueryResult = Apollo.QueryResult<BrowseSellersQuery, BrowseSellersQueryVariables>;
export const BrowseSellersFiltersDocument = gql`
    query BrowseSellersFilters {
  prices {
    data {
      id
      attributes {
        option
      }
    }
  }
  industries(sort: "option:asc") {
    data {
      id
      attributes {
        option
      }
    }
  }
  countries {
    data {
      id
      attributes {
        option
      }
    }
  }
  editdaMargins {
    data {
      id
      attributes {
        option
      }
    }
  }
  desiredTransactions {
    data {
      id
      attributes {
        option
      }
    }
  }
  idealBuyers {
    data {
      id
      attributes {
        option
      }
    }
  }
  sellingReasons {
    data {
      attributes {
        option
      }
      id
    }
  }
  targetExitPeriods {
    data {
      id
      attributes {
        option
      }
    }
  }
  profitabilities {
    data {
      id
      attributes {
        option
      }
    }
  }
  annualRevenues(sort: "createdAt:asc") {
    data {
      id
      attributes {
        option
      }
    }
  }
  numberOfEmployees {
    data {
      id
      attributes {
        option
      }
    }
  }
}
    `;
export function useBrowseSellersFiltersQuery(baseOptions?: Apollo.QueryHookOptions<BrowseSellersFiltersQuery, BrowseSellersFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrowseSellersFiltersQuery, BrowseSellersFiltersQueryVariables>(BrowseSellersFiltersDocument, options);
      }
export function useBrowseSellersFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrowseSellersFiltersQuery, BrowseSellersFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrowseSellersFiltersQuery, BrowseSellersFiltersQueryVariables>(BrowseSellersFiltersDocument, options);
        }
export function useBrowseSellersFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BrowseSellersFiltersQuery, BrowseSellersFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BrowseSellersFiltersQuery, BrowseSellersFiltersQueryVariables>(BrowseSellersFiltersDocument, options);
        }
export type BrowseSellersFiltersQueryHookResult = ReturnType<typeof useBrowseSellersFiltersQuery>;
export type BrowseSellersFiltersLazyQueryHookResult = ReturnType<typeof useBrowseSellersFiltersLazyQuery>;
export type BrowseSellersFiltersSuspenseQueryHookResult = ReturnType<typeof useBrowseSellersFiltersSuspenseQuery>;
export type BrowseSellersFiltersQueryResult = Apollo.QueryResult<BrowseSellersFiltersQuery, BrowseSellersFiltersQueryVariables>;
export const BrowseSellersHeroDocument = gql`
    query BrowseSellersHero {
  seller {
    data {
      attributes {
        description
        title
        subtitle
        buttonText
        buttonVisible
        img {
          data {
            attributes {
              url
            }
          }
        }
        imgTablet {
          data {
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
    `;
export function useBrowseSellersHeroQuery(baseOptions?: Apollo.QueryHookOptions<BrowseSellersHeroQuery, BrowseSellersHeroQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrowseSellersHeroQuery, BrowseSellersHeroQueryVariables>(BrowseSellersHeroDocument, options);
      }
export function useBrowseSellersHeroLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrowseSellersHeroQuery, BrowseSellersHeroQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrowseSellersHeroQuery, BrowseSellersHeroQueryVariables>(BrowseSellersHeroDocument, options);
        }
export function useBrowseSellersHeroSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BrowseSellersHeroQuery, BrowseSellersHeroQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BrowseSellersHeroQuery, BrowseSellersHeroQueryVariables>(BrowseSellersHeroDocument, options);
        }
export type BrowseSellersHeroQueryHookResult = ReturnType<typeof useBrowseSellersHeroQuery>;
export type BrowseSellersHeroLazyQueryHookResult = ReturnType<typeof useBrowseSellersHeroLazyQuery>;
export type BrowseSellersHeroSuspenseQueryHookResult = ReturnType<typeof useBrowseSellersHeroSuspenseQuery>;
export type BrowseSellersHeroQueryResult = Apollo.QueryResult<BrowseSellersHeroQuery, BrowseSellersHeroQueryVariables>;
export const BrowseSellersTotalCountDocument = gql`
    query browseSellersTotalCount($industriesAndSubSectors: [PropertyFiltersInput], $prices: [ID], $annualRevenues: [ID], $countries: [ID], $mainMarkets: [ID], $editdaMargins: [ID], $transactions: [ID], $searchBox: String, $tagSearch: [PropertyFiltersInput], $sourceInnerFilters: [PropertyFavoriteFiltersInput]) {
  properties(
    filters: {and: {or: [{companyName: {containsi: $searchBox}}, {description: {containsi: $searchBox}}, {businessDescription: {containsi: $searchBox}}, {productsAndServices: {containsi: $searchBox}}, {highlightsUniqueSellingPoint: {containsi: $searchBox}}, {reasonForSale: {containsi: $searchBox}}, {externalId: {containsi: $searchBox}}, {or: $tagSearch}, {industries: {option: {containsi: $searchBox}}}, {subSectors: {option: {containsi: $searchBox}}}, {askingPrice: {option: {containsi: $searchBox}}}, {annualRevenue: {option: {containsi: $searchBox}}}, {country: {option: {containsi: $searchBox}}}, {margin: {option: {containsi: $searchBox}}}, {desiredTransaction: {option: {containsi: $searchBox}}}, {idealBuyer: {option: {containsi: $searchBox}}}, {mainMarket: {option: {containsi: $searchBox}}}, {profitability: {option: {containsi: $searchBox}}}, {margin: {option: {containsi: $searchBox}}}]}, askingPrice: {id: {in: $prices}}, annualRevenue: {id: {in: $annualRevenues}}, country: {id: {in: $countries}}, mainMarket: {id: {in: $mainMarkets}}, margin: {id: {in: $editdaMargins}}, desiredTransaction: {id: {in: $transactions}}, or: $industriesAndSubSectors, propertyFavorites: {or: $sourceInnerFilters}}
  ) {
    data {
      id
      attributes {
        companyName
        description
        confirmed
        annualRevenue {
          data {
            id
            attributes {
              option
            }
          }
        }
        image {
          data {
            attributes {
              url
            }
          }
        }
        industries {
          data {
            attributes {
              option
            }
          }
        }
        subSectors {
          data {
            attributes {
              option
            }
          }
        }
        askingPrice {
          data {
            id
            attributes {
              option
            }
          }
        }
        margin {
          data {
            id
            attributes {
              option
            }
          }
        }
        country {
          data {
            attributes {
              option
            }
          }
        }
        desiredTransaction {
          data {
            attributes {
              option
            }
          }
        }
      }
    }
  }
}
    `;
export function useBrowseSellersTotalCountQuery(baseOptions?: Apollo.QueryHookOptions<BrowseSellersTotalCountQuery, BrowseSellersTotalCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrowseSellersTotalCountQuery, BrowseSellersTotalCountQueryVariables>(BrowseSellersTotalCountDocument, options);
      }
export function useBrowseSellersTotalCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrowseSellersTotalCountQuery, BrowseSellersTotalCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrowseSellersTotalCountQuery, BrowseSellersTotalCountQueryVariables>(BrowseSellersTotalCountDocument, options);
        }
export function useBrowseSellersTotalCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BrowseSellersTotalCountQuery, BrowseSellersTotalCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BrowseSellersTotalCountQuery, BrowseSellersTotalCountQueryVariables>(BrowseSellersTotalCountDocument, options);
        }
export type BrowseSellersTotalCountQueryHookResult = ReturnType<typeof useBrowseSellersTotalCountQuery>;
export type BrowseSellersTotalCountLazyQueryHookResult = ReturnType<typeof useBrowseSellersTotalCountLazyQuery>;
export type BrowseSellersTotalCountSuspenseQueryHookResult = ReturnType<typeof useBrowseSellersTotalCountSuspenseQuery>;
export type BrowseSellersTotalCountQueryResult = Apollo.QueryResult<BrowseSellersTotalCountQuery, BrowseSellersTotalCountQueryVariables>;
export const BuyerGuideDocument = gql`
    query BuyerGuide {
  buyerGuide {
    data {
      attributes {
        acquiringBusiness
        singingUp
        verification
        viewListings
        sellerRegistration {
          ...QA
        }
        listing {
          ...QA
        }
        matching {
          ...QA
        }
        closingTheDeal {
          ...QA
        }
      }
    }
  }
}
    ${QaFragmentDoc}`;
export function useBuyerGuideQuery(baseOptions?: Apollo.QueryHookOptions<BuyerGuideQuery, BuyerGuideQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BuyerGuideQuery, BuyerGuideQueryVariables>(BuyerGuideDocument, options);
      }
export function useBuyerGuideLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BuyerGuideQuery, BuyerGuideQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BuyerGuideQuery, BuyerGuideQueryVariables>(BuyerGuideDocument, options);
        }
export function useBuyerGuideSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BuyerGuideQuery, BuyerGuideQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BuyerGuideQuery, BuyerGuideQueryVariables>(BuyerGuideDocument, options);
        }
export type BuyerGuideQueryHookResult = ReturnType<typeof useBuyerGuideQuery>;
export type BuyerGuideLazyQueryHookResult = ReturnType<typeof useBuyerGuideLazyQuery>;
export type BuyerGuideSuspenseQueryHookResult = ReturnType<typeof useBuyerGuideSuspenseQuery>;
export type BuyerGuideQueryResult = Apollo.QueryResult<BuyerGuideQuery, BuyerGuideQueryVariables>;
export const BuyerPropertyByIdDocument = gql`
    query buyerPropertyById($id: ID) {
  propertyBuyer(id: $id) {
    data {
      id
      attributes {
        companyName
        description
        confirmed
        annualRevenue
        historicalGrowthRate
        projectedGrowthRate
        profitability
        revenueRange {
          data {
            attributes {
              option
            }
          }
        }
        typeOfInvestor {
          data {
            attributes {
              option
            }
          }
        }
        image {
          data {
            attributes {
              url
            }
          }
        }
        industry {
          data {
            id
            attributes {
              option
            }
          }
        }
        country {
          data {
            id
            attributes {
              option
            }
          }
        }
        aboutProperty
        ticketSize {
          data {
            id
            attributes {
              option
            }
          }
        }
        revenueRange {
          data {
            id
            attributes {
              option
            }
          }
        }
        investmentStyle {
          data {
            id
            attributes {
              option
            }
          }
        }
        desiredEditda {
          data {
            id
            attributes {
              option
            }
          }
        }
        website
        users {
          data {
            id
            attributes {
              phone
              email
              username
            }
          }
        }
      }
    }
  }
}
    `;
export function useBuyerPropertyByIdQuery(baseOptions?: Apollo.QueryHookOptions<BuyerPropertyByIdQuery, BuyerPropertyByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BuyerPropertyByIdQuery, BuyerPropertyByIdQueryVariables>(BuyerPropertyByIdDocument, options);
      }
export function useBuyerPropertyByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BuyerPropertyByIdQuery, BuyerPropertyByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BuyerPropertyByIdQuery, BuyerPropertyByIdQueryVariables>(BuyerPropertyByIdDocument, options);
        }
export function useBuyerPropertyByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BuyerPropertyByIdQuery, BuyerPropertyByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BuyerPropertyByIdQuery, BuyerPropertyByIdQueryVariables>(BuyerPropertyByIdDocument, options);
        }
export type BuyerPropertyByIdQueryHookResult = ReturnType<typeof useBuyerPropertyByIdQuery>;
export type BuyerPropertyByIdLazyQueryHookResult = ReturnType<typeof useBuyerPropertyByIdLazyQuery>;
export type BuyerPropertyByIdSuspenseQueryHookResult = ReturnType<typeof useBuyerPropertyByIdSuspenseQuery>;
export type BuyerPropertyByIdQueryResult = Apollo.QueryResult<BuyerPropertyByIdQuery, BuyerPropertyByIdQueryVariables>;
export const BuyerPropertyByNameDocument = gql`
    query buyerPropertyByName($name: String) {
  propertyBuyers(filters: {companyName: {containsi: $name}}) {
    data {
      id
      attributes {
        companyName
        description
        confirmed
        annualRevenue
        historicalGrowthRate
        projectedGrowthRate
        profitability
        revenueRange {
          data {
            attributes {
              option
            }
          }
        }
        typeOfInvestor {
          data {
            attributes {
              option
            }
          }
        }
        image {
          data {
            attributes {
              url
            }
          }
        }
        industry {
          data {
            id
            attributes {
              option
            }
          }
        }
        country {
          data {
            id
            attributes {
              option
            }
          }
        }
        aboutProperty
        ticketSize {
          data {
            id
            attributes {
              option
            }
          }
        }
        revenueRange {
          data {
            id
            attributes {
              option
            }
          }
        }
        investmentStyle {
          data {
            id
            attributes {
              option
            }
          }
        }
        desiredEditda {
          data {
            id
            attributes {
              option
            }
          }
        }
        website
        users {
          data {
            id
            attributes {
              phone
              email
              username
            }
          }
        }
      }
    }
  }
}
    `;
export function useBuyerPropertyByNameQuery(baseOptions?: Apollo.QueryHookOptions<BuyerPropertyByNameQuery, BuyerPropertyByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BuyerPropertyByNameQuery, BuyerPropertyByNameQueryVariables>(BuyerPropertyByNameDocument, options);
      }
export function useBuyerPropertyByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BuyerPropertyByNameQuery, BuyerPropertyByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BuyerPropertyByNameQuery, BuyerPropertyByNameQueryVariables>(BuyerPropertyByNameDocument, options);
        }
export function useBuyerPropertyByNameSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BuyerPropertyByNameQuery, BuyerPropertyByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BuyerPropertyByNameQuery, BuyerPropertyByNameQueryVariables>(BuyerPropertyByNameDocument, options);
        }
export type BuyerPropertyByNameQueryHookResult = ReturnType<typeof useBuyerPropertyByNameQuery>;
export type BuyerPropertyByNameLazyQueryHookResult = ReturnType<typeof useBuyerPropertyByNameLazyQuery>;
export type BuyerPropertyByNameSuspenseQueryHookResult = ReturnType<typeof useBuyerPropertyByNameSuspenseQuery>;
export type BuyerPropertyByNameQueryResult = Apollo.QueryResult<BuyerPropertyByNameQuery, BuyerPropertyByNameQueryVariables>;
export const ContactInfoDocument = gql`
    query ContactInfo {
  contactUsInfo {
    data {
      attributes {
        info
      }
    }
  }
}
    `;
export function useContactInfoQuery(baseOptions?: Apollo.QueryHookOptions<ContactInfoQuery, ContactInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactInfoQuery, ContactInfoQueryVariables>(ContactInfoDocument, options);
      }
export function useContactInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactInfoQuery, ContactInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactInfoQuery, ContactInfoQueryVariables>(ContactInfoDocument, options);
        }
export function useContactInfoSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ContactInfoQuery, ContactInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ContactInfoQuery, ContactInfoQueryVariables>(ContactInfoDocument, options);
        }
export type ContactInfoQueryHookResult = ReturnType<typeof useContactInfoQuery>;
export type ContactInfoLazyQueryHookResult = ReturnType<typeof useContactInfoLazyQuery>;
export type ContactInfoSuspenseQueryHookResult = ReturnType<typeof useContactInfoSuspenseQuery>;
export type ContactInfoQueryResult = Apollo.QueryResult<ContactInfoQuery, ContactInfoQueryVariables>;
export const DashboardDocument = gql`
    query Dashboard {
  dashboard {
    industries {
      name
      value
    }
    mainMarkets {
      name
      value
    }
    desiredTransactions {
      name
      value
    }
    askingPrices {
      name
      value
    }
    annualRevenues {
      name
      value
    }
  }
}
    `;
export function useDashboardQuery(baseOptions?: Apollo.QueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, options);
      }
export function useDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, options);
        }
export function useDashboardSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, options);
        }
export type DashboardQueryHookResult = ReturnType<typeof useDashboardQuery>;
export type DashboardLazyQueryHookResult = ReturnType<typeof useDashboardLazyQuery>;
export type DashboardSuspenseQueryHookResult = ReturnType<typeof useDashboardSuspenseQuery>;
export type DashboardQueryResult = Apollo.QueryResult<DashboardQuery, DashboardQueryVariables>;
export const FiltersSortedDocument = gql`
    query filtersSorted {
  filterSorted {
    data {
      attributes {
        type_of_investors {
          data {
            id
            attributes {
              option
            }
          }
        }
        industries_registration {
          data {
            id
            attributes {
              option
            }
          }
        }
        industries_search {
          data {
            id
            attributes {
              option
              subSectors(sort: "option:asc") {
                data {
                  id
                  attributes {
                    option
                  }
                }
              }
            }
          }
        }
        countries {
          data {
            id
            attributes {
              option
            }
          }
        }
        tickets {
          data {
            id
            attributes {
              option
            }
          }
        }
        ebitdas {
          data {
            id
            attributes {
              option
            }
          }
        }
        profitabilities {
          data {
            id
            attributes {
              option
            }
          }
        }
        profit_margins {
          data {
            id
            attributes {
              option
            }
          }
        }
        type_of_target_valuations {
          data {
            id
            attributes {
              option
            }
          }
        }
        target_valuations {
          data {
            id
            attributes {
              option
            }
          }
        }
        annual_revenues {
          data {
            id
            attributes {
              option
            }
          }
        }
      }
    }
  }
}
    `;
export function useFiltersSortedQuery(baseOptions?: Apollo.QueryHookOptions<FiltersSortedQuery, FiltersSortedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FiltersSortedQuery, FiltersSortedQueryVariables>(FiltersSortedDocument, options);
      }
export function useFiltersSortedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FiltersSortedQuery, FiltersSortedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FiltersSortedQuery, FiltersSortedQueryVariables>(FiltersSortedDocument, options);
        }
export function useFiltersSortedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FiltersSortedQuery, FiltersSortedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FiltersSortedQuery, FiltersSortedQueryVariables>(FiltersSortedDocument, options);
        }
export type FiltersSortedQueryHookResult = ReturnType<typeof useFiltersSortedQuery>;
export type FiltersSortedLazyQueryHookResult = ReturnType<typeof useFiltersSortedLazyQuery>;
export type FiltersSortedSuspenseQueryHookResult = ReturnType<typeof useFiltersSortedSuspenseQuery>;
export type FiltersSortedQueryResult = Apollo.QueryResult<FiltersSortedQuery, FiltersSortedQueryVariables>;
export const FooterLinkGroupsDocument = gql`
    query footerLinkGroups {
  footerLinkGroups {
    data {
      attributes {
        groupName
        url
        groupLink {
          ...Link
        }
      }
    }
  }
}
    ${LinkFragmentDoc}`;
export function useFooterLinkGroupsQuery(baseOptions?: Apollo.QueryHookOptions<FooterLinkGroupsQuery, FooterLinkGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FooterLinkGroupsQuery, FooterLinkGroupsQueryVariables>(FooterLinkGroupsDocument, options);
      }
export function useFooterLinkGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FooterLinkGroupsQuery, FooterLinkGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FooterLinkGroupsQuery, FooterLinkGroupsQueryVariables>(FooterLinkGroupsDocument, options);
        }
export function useFooterLinkGroupsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FooterLinkGroupsQuery, FooterLinkGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FooterLinkGroupsQuery, FooterLinkGroupsQueryVariables>(FooterLinkGroupsDocument, options);
        }
export type FooterLinkGroupsQueryHookResult = ReturnType<typeof useFooterLinkGroupsQuery>;
export type FooterLinkGroupsLazyQueryHookResult = ReturnType<typeof useFooterLinkGroupsLazyQuery>;
export type FooterLinkGroupsSuspenseQueryHookResult = ReturnType<typeof useFooterLinkGroupsSuspenseQuery>;
export type FooterLinkGroupsQueryResult = Apollo.QueryResult<FooterLinkGroupsQuery, FooterLinkGroupsQueryVariables>;
export const FoundersDocument = gql`
    query Founders {
  home {
    data {
      attributes {
        founders {
          align
          description
          founderCompaniesImgs {
            companyLogo {
              data {
                attributes {
                  url
                }
              }
            }
          }
          image {
            data {
              attributes {
                url
              }
            }
          }
          name
        }
      }
    }
  }
}
    `;
export function useFoundersQuery(baseOptions?: Apollo.QueryHookOptions<FoundersQuery, FoundersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FoundersQuery, FoundersQueryVariables>(FoundersDocument, options);
      }
export function useFoundersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FoundersQuery, FoundersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FoundersQuery, FoundersQueryVariables>(FoundersDocument, options);
        }
export function useFoundersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FoundersQuery, FoundersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FoundersQuery, FoundersQueryVariables>(FoundersDocument, options);
        }
export type FoundersQueryHookResult = ReturnType<typeof useFoundersQuery>;
export type FoundersLazyQueryHookResult = ReturnType<typeof useFoundersLazyQuery>;
export type FoundersSuspenseQueryHookResult = ReturnType<typeof useFoundersSuspenseQuery>;
export type FoundersQueryResult = Apollo.QueryResult<FoundersQuery, FoundersQueryVariables>;
export const GetArticleByIdDocument = gql`
    query GetArticleById($title: String) {
  articles(filters: {title: {containsi: $title}}) {
    data {
      id
      attributes {
        createdAt
        content
        author
        mainImg {
          data {
            attributes {
              url
            }
          }
        }
        title
      }
    }
  }
}
    `;
export function useGetArticleByIdQuery(baseOptions?: Apollo.QueryHookOptions<GetArticleByIdQuery, GetArticleByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArticleByIdQuery, GetArticleByIdQueryVariables>(GetArticleByIdDocument, options);
      }
export function useGetArticleByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArticleByIdQuery, GetArticleByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArticleByIdQuery, GetArticleByIdQueryVariables>(GetArticleByIdDocument, options);
        }
export function useGetArticleByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetArticleByIdQuery, GetArticleByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetArticleByIdQuery, GetArticleByIdQueryVariables>(GetArticleByIdDocument, options);
        }
export type GetArticleByIdQueryHookResult = ReturnType<typeof useGetArticleByIdQuery>;
export type GetArticleByIdLazyQueryHookResult = ReturnType<typeof useGetArticleByIdLazyQuery>;
export type GetArticleByIdSuspenseQueryHookResult = ReturnType<typeof useGetArticleByIdSuspenseQuery>;
export type GetArticleByIdQueryResult = Apollo.QueryResult<GetArticleByIdQuery, GetArticleByIdQueryVariables>;
export const GetArticlesDocument = gql`
    query GetArticles {
  articles {
    data {
      id
      attributes {
        createdAt
        content
        author
        mainImg {
          data {
            attributes {
              url
            }
          }
        }
        title
        cardDescription
      }
    }
  }
}
    `;
export function useGetArticlesQuery(baseOptions?: Apollo.QueryHookOptions<GetArticlesQuery, GetArticlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArticlesQuery, GetArticlesQueryVariables>(GetArticlesDocument, options);
      }
export function useGetArticlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArticlesQuery, GetArticlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArticlesQuery, GetArticlesQueryVariables>(GetArticlesDocument, options);
        }
export function useGetArticlesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetArticlesQuery, GetArticlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetArticlesQuery, GetArticlesQueryVariables>(GetArticlesDocument, options);
        }
export type GetArticlesQueryHookResult = ReturnType<typeof useGetArticlesQuery>;
export type GetArticlesLazyQueryHookResult = ReturnType<typeof useGetArticlesLazyQuery>;
export type GetArticlesSuspenseQueryHookResult = ReturnType<typeof useGetArticlesSuspenseQuery>;
export type GetArticlesQueryResult = Apollo.QueryResult<GetArticlesQuery, GetArticlesQueryVariables>;
export const GetContactSellerByPropertyIdDocument = gql`
    query getContactSellerByPropertyId($id: ID) {
  contactSeller(id: $id) {
    data {
      id
    }
  }
}
    `;
export function useGetContactSellerByPropertyIdQuery(baseOptions?: Apollo.QueryHookOptions<GetContactSellerByPropertyIdQuery, GetContactSellerByPropertyIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactSellerByPropertyIdQuery, GetContactSellerByPropertyIdQueryVariables>(GetContactSellerByPropertyIdDocument, options);
      }
export function useGetContactSellerByPropertyIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactSellerByPropertyIdQuery, GetContactSellerByPropertyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactSellerByPropertyIdQuery, GetContactSellerByPropertyIdQueryVariables>(GetContactSellerByPropertyIdDocument, options);
        }
export function useGetContactSellerByPropertyIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetContactSellerByPropertyIdQuery, GetContactSellerByPropertyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetContactSellerByPropertyIdQuery, GetContactSellerByPropertyIdQueryVariables>(GetContactSellerByPropertyIdDocument, options);
        }
export type GetContactSellerByPropertyIdQueryHookResult = ReturnType<typeof useGetContactSellerByPropertyIdQuery>;
export type GetContactSellerByPropertyIdLazyQueryHookResult = ReturnType<typeof useGetContactSellerByPropertyIdLazyQuery>;
export type GetContactSellerByPropertyIdSuspenseQueryHookResult = ReturnType<typeof useGetContactSellerByPropertyIdSuspenseQuery>;
export type GetContactSellerByPropertyIdQueryResult = Apollo.QueryResult<GetContactSellerByPropertyIdQuery, GetContactSellerByPropertyIdQueryVariables>;
export const GetGuideTemplatesDocument = gql`
    query GetGuideTemplates {
  guideTemplates {
    data {
      id
      attributes {
        createdAt
        mainImg {
          data {
            attributes {
              url
            }
          }
        }
        title
        cardDescription
        hsClassName
      }
    }
  }
}
    `;
export function useGetGuideTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetGuideTemplatesQuery, GetGuideTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGuideTemplatesQuery, GetGuideTemplatesQueryVariables>(GetGuideTemplatesDocument, options);
      }
export function useGetGuideTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGuideTemplatesQuery, GetGuideTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGuideTemplatesQuery, GetGuideTemplatesQueryVariables>(GetGuideTemplatesDocument, options);
        }
export function useGetGuideTemplatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetGuideTemplatesQuery, GetGuideTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGuideTemplatesQuery, GetGuideTemplatesQueryVariables>(GetGuideTemplatesDocument, options);
        }
export type GetGuideTemplatesQueryHookResult = ReturnType<typeof useGetGuideTemplatesQuery>;
export type GetGuideTemplatesLazyQueryHookResult = ReturnType<typeof useGetGuideTemplatesLazyQuery>;
export type GetGuideTemplatesSuspenseQueryHookResult = ReturnType<typeof useGetGuideTemplatesSuspenseQuery>;
export type GetGuideTemplatesQueryResult = Apollo.QueryResult<GetGuideTemplatesQuery, GetGuideTemplatesQueryVariables>;
export const HomeDocument = gql`
    query home {
  home {
    data {
      attributes {
        hero {
          ...Section
        }
        empowerYourJourneySection {
          ...SectionWithCards
        }
        idealTarget {
          ...IdealTarget
        }
        matchingSellersBuyersSection {
          ...MatchingSellersAndBuyersSection
        }
        omniaAdvantageSection {
          ...SectionWithComparisonCards
        }
        foundersHeading {
          ...Paragraph
        }
        founders {
          ...Founders
        }
        qa {
          ...QA
        }
      }
    }
  }
}
    ${SectionFragmentDoc}
${LinkFragmentDoc}
${CardFragmentDoc}
${FileFragmentDoc}
${SectionWithCardsFragmentDoc}
${IdealTargetFragmentDoc}
${MatchingSellersAndBuyersSectionFragmentDoc}
${SectionWithComparisonCardsFragmentDoc}
${ComparisonCardFragmentDoc}
${ParagraphFragmentDoc}
${FoundersFragmentDoc}
${QaFragmentDoc}`;
export function useHomeQuery(baseOptions?: Apollo.QueryHookOptions<HomeQuery, HomeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
      }
export function useHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeQuery, HomeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
        }
export function useHomeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HomeQuery, HomeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
        }
export type HomeQueryHookResult = ReturnType<typeof useHomeQuery>;
export type HomeLazyQueryHookResult = ReturnType<typeof useHomeLazyQuery>;
export type HomeSuspenseQueryHookResult = ReturnType<typeof useHomeSuspenseQuery>;
export type HomeQueryResult = Apollo.QueryResult<HomeQuery, HomeQueryVariables>;
export const HomeClosedDealsDocument = gql`
    query homeClosedDeals {
  closedDeals {
    data {
      attributes {
        industry {
          data {
            attributes {
              option
            }
          }
        }
        companyName
        companyImage {
          data {
            attributes {
              url
            }
          }
        }
        shortDescription
      }
    }
  }
}
    `;
export function useHomeClosedDealsQuery(baseOptions?: Apollo.QueryHookOptions<HomeClosedDealsQuery, HomeClosedDealsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeClosedDealsQuery, HomeClosedDealsQueryVariables>(HomeClosedDealsDocument, options);
      }
export function useHomeClosedDealsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeClosedDealsQuery, HomeClosedDealsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeClosedDealsQuery, HomeClosedDealsQueryVariables>(HomeClosedDealsDocument, options);
        }
export function useHomeClosedDealsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HomeClosedDealsQuery, HomeClosedDealsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HomeClosedDealsQuery, HomeClosedDealsQueryVariables>(HomeClosedDealsDocument, options);
        }
export type HomeClosedDealsQueryHookResult = ReturnType<typeof useHomeClosedDealsQuery>;
export type HomeClosedDealsLazyQueryHookResult = ReturnType<typeof useHomeClosedDealsLazyQuery>;
export type HomeClosedDealsSuspenseQueryHookResult = ReturnType<typeof useHomeClosedDealsSuspenseQuery>;
export type HomeClosedDealsQueryResult = Apollo.QueryResult<HomeClosedDealsQuery, HomeClosedDealsQueryVariables>;
export const HomeIndustriesDocument = gql`
    query homeIndustries {
  industries {
    data {
      id
      attributes {
        option
      }
    }
  }
}
    `;
export function useHomeIndustriesQuery(baseOptions?: Apollo.QueryHookOptions<HomeIndustriesQuery, HomeIndustriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeIndustriesQuery, HomeIndustriesQueryVariables>(HomeIndustriesDocument, options);
      }
export function useHomeIndustriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeIndustriesQuery, HomeIndustriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeIndustriesQuery, HomeIndustriesQueryVariables>(HomeIndustriesDocument, options);
        }
export function useHomeIndustriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HomeIndustriesQuery, HomeIndustriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HomeIndustriesQuery, HomeIndustriesQueryVariables>(HomeIndustriesDocument, options);
        }
export type HomeIndustriesQueryHookResult = ReturnType<typeof useHomeIndustriesQuery>;
export type HomeIndustriesLazyQueryHookResult = ReturnType<typeof useHomeIndustriesLazyQuery>;
export type HomeIndustriesSuspenseQueryHookResult = ReturnType<typeof useHomeIndustriesSuspenseQuery>;
export type HomeIndustriesQueryResult = Apollo.QueryResult<HomeIndustriesQuery, HomeIndustriesQueryVariables>;
export const HomePropertiesDocument = gql`
    query homeProperties($industryId: [ID]) {
  properties(filters: {industries: {id: {in: $industryId}}}) {
    data {
      id
      attributes {
        companyName
        description
        confirmed
        annualRevenue {
          data {
            id
            attributes {
              option
            }
          }
        }
        yearOfIncorporation
        profitability {
          data {
            attributes {
              option
            }
          }
        }
        image {
          data {
            attributes {
              url
            }
          }
        }
        industries {
          data {
            attributes {
              option
            }
          }
        }
        askingPrice {
          data {
            id
            attributes {
              option
            }
          }
        }
        margin {
          data {
            id
            attributes {
              option
            }
          }
        }
        country {
          data {
            attributes {
              option
            }
          }
        }
        desiredTransaction {
          data {
            attributes {
              option
            }
          }
        }
      }
    }
  }
}
    `;
export function useHomePropertiesQuery(baseOptions?: Apollo.QueryHookOptions<HomePropertiesQuery, HomePropertiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomePropertiesQuery, HomePropertiesQueryVariables>(HomePropertiesDocument, options);
      }
export function useHomePropertiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomePropertiesQuery, HomePropertiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomePropertiesQuery, HomePropertiesQueryVariables>(HomePropertiesDocument, options);
        }
export function useHomePropertiesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HomePropertiesQuery, HomePropertiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HomePropertiesQuery, HomePropertiesQueryVariables>(HomePropertiesDocument, options);
        }
export type HomePropertiesQueryHookResult = ReturnType<typeof useHomePropertiesQuery>;
export type HomePropertiesLazyQueryHookResult = ReturnType<typeof useHomePropertiesLazyQuery>;
export type HomePropertiesSuspenseQueryHookResult = ReturnType<typeof useHomePropertiesSuspenseQuery>;
export type HomePropertiesQueryResult = Apollo.QueryResult<HomePropertiesQuery, HomePropertiesQueryVariables>;
export const IpDocument = gql`
    query ip {
  ip
}
    `;
export function useIpQuery(baseOptions?: Apollo.QueryHookOptions<IpQuery, IpQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IpQuery, IpQueryVariables>(IpDocument, options);
      }
export function useIpLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IpQuery, IpQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IpQuery, IpQueryVariables>(IpDocument, options);
        }
export function useIpSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IpQuery, IpQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IpQuery, IpQueryVariables>(IpDocument, options);
        }
export type IpQueryHookResult = ReturnType<typeof useIpQuery>;
export type IpLazyQueryHookResult = ReturnType<typeof useIpLazyQuery>;
export type IpSuspenseQueryHookResult = ReturnType<typeof useIpSuspenseQuery>;
export type IpQueryResult = Apollo.QueryResult<IpQuery, IpQueryVariables>;
export const LayoutDocument = gql`
    query layout {
  layout {
    data {
      attributes {
        headerMenu {
          ...Link
        }
        userMenu {
          ...Link
        }
        rights
        policies {
          ...Link
        }
      }
    }
  }
}
    ${LinkFragmentDoc}`;
export function useLayoutQuery(baseOptions?: Apollo.QueryHookOptions<LayoutQuery, LayoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LayoutQuery, LayoutQueryVariables>(LayoutDocument, options);
      }
export function useLayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LayoutQuery, LayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LayoutQuery, LayoutQueryVariables>(LayoutDocument, options);
        }
export function useLayoutSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LayoutQuery, LayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LayoutQuery, LayoutQueryVariables>(LayoutDocument, options);
        }
export type LayoutQueryHookResult = ReturnType<typeof useLayoutQuery>;
export type LayoutLazyQueryHookResult = ReturnType<typeof useLayoutLazyQuery>;
export type LayoutSuspenseQueryHookResult = ReturnType<typeof useLayoutSuspenseQuery>;
export type LayoutQueryResult = Apollo.QueryResult<LayoutQuery, LayoutQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
  }
}
    `;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export function useMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const PricingDocument = gql`
    query Pricing {
  pricing {
    data {
      attributes {
        boldIntro
        thinIntro
        icon {
          data {
            attributes {
              url
            }
          }
        }
        img {
          data {
            attributes {
              url
            }
          }
        }
        options {
          label
          text
        }
        sectionHeader
      }
    }
  }
}
    `;
export function usePricingQuery(baseOptions?: Apollo.QueryHookOptions<PricingQuery, PricingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PricingQuery, PricingQueryVariables>(PricingDocument, options);
      }
export function usePricingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PricingQuery, PricingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PricingQuery, PricingQueryVariables>(PricingDocument, options);
        }
export function usePricingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PricingQuery, PricingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PricingQuery, PricingQueryVariables>(PricingDocument, options);
        }
export type PricingQueryHookResult = ReturnType<typeof usePricingQuery>;
export type PricingLazyQueryHookResult = ReturnType<typeof usePricingLazyQuery>;
export type PricingSuspenseQueryHookResult = ReturnType<typeof usePricingSuspenseQuery>;
export type PricingQueryResult = Apollo.QueryResult<PricingQuery, PricingQueryVariables>;
export const PrivacyPolicyDocument = gql`
    query privacyPolicy {
  privacyPolicy {
    data {
      attributes {
        title
        content
      }
    }
  }
}
    `;
export function usePrivacyPolicyQuery(baseOptions?: Apollo.QueryHookOptions<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>(PrivacyPolicyDocument, options);
      }
export function usePrivacyPolicyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>(PrivacyPolicyDocument, options);
        }
export function usePrivacyPolicySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>(PrivacyPolicyDocument, options);
        }
export type PrivacyPolicyQueryHookResult = ReturnType<typeof usePrivacyPolicyQuery>;
export type PrivacyPolicyLazyQueryHookResult = ReturnType<typeof usePrivacyPolicyLazyQuery>;
export type PrivacyPolicySuspenseQueryHookResult = ReturnType<typeof usePrivacyPolicySuspenseQuery>;
export type PrivacyPolicyQueryResult = Apollo.QueryResult<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>;
export const ResourcePageDocument = gql`
    query ResourcePage {
  resource {
    data {
      attributes {
        joinUs
        leftText
        rightText
        createdAt
      }
    }
  }
}
    `;
export function useResourcePageQuery(baseOptions?: Apollo.QueryHookOptions<ResourcePageQuery, ResourcePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResourcePageQuery, ResourcePageQueryVariables>(ResourcePageDocument, options);
      }
export function useResourcePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourcePageQuery, ResourcePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResourcePageQuery, ResourcePageQueryVariables>(ResourcePageDocument, options);
        }
export function useResourcePageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ResourcePageQuery, ResourcePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ResourcePageQuery, ResourcePageQueryVariables>(ResourcePageDocument, options);
        }
export type ResourcePageQueryHookResult = ReturnType<typeof useResourcePageQuery>;
export type ResourcePageLazyQueryHookResult = ReturnType<typeof useResourcePageLazyQuery>;
export type ResourcePageSuspenseQueryHookResult = ReturnType<typeof useResourcePageSuspenseQuery>;
export type ResourcePageQueryResult = Apollo.QueryResult<ResourcePageQuery, ResourcePageQueryVariables>;
export const SellerGuideDocument = gql`
    query SellerGuide {
  sellerGuide {
    data {
      attributes {
        acquiringBusiness
        singingUp
        verification
        viewListings
        sellerRegistration {
          ...QA
        }
        listing {
          ...QA
        }
        matching {
          ...QA
        }
        closingTheDeal {
          ...QA
        }
      }
    }
  }
}
    ${QaFragmentDoc}`;
export function useSellerGuideQuery(baseOptions?: Apollo.QueryHookOptions<SellerGuideQuery, SellerGuideQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SellerGuideQuery, SellerGuideQueryVariables>(SellerGuideDocument, options);
      }
export function useSellerGuideLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SellerGuideQuery, SellerGuideQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SellerGuideQuery, SellerGuideQueryVariables>(SellerGuideDocument, options);
        }
export function useSellerGuideSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SellerGuideQuery, SellerGuideQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SellerGuideQuery, SellerGuideQueryVariables>(SellerGuideDocument, options);
        }
export type SellerGuideQueryHookResult = ReturnType<typeof useSellerGuideQuery>;
export type SellerGuideLazyQueryHookResult = ReturnType<typeof useSellerGuideLazyQuery>;
export type SellerGuideSuspenseQueryHookResult = ReturnType<typeof useSellerGuideSuspenseQuery>;
export type SellerGuideQueryResult = Apollo.QueryResult<SellerGuideQuery, SellerGuideQueryVariables>;
export const SellerPropertyByIdDocument = gql`
    query sellerPropertyById($id: ID, $sourceFilters: [PropertyFavoriteFiltersInput]) {
  property(id: $id) {
    data {
      id
      attributes {
        companyName
        description
        confirmed
        annualRevenue {
          data {
            id
            attributes {
              option
            }
          }
        }
        historicalRevenueGrowth
        projectedRevenueGrowth
        profitability {
          data {
            attributes {
              option
            }
          }
        }
        image {
          data {
            attributes {
              url
            }
          }
        }
        market {
          data {
            attributes {
              url
            }
          }
        }
        industries {
          data {
            attributes {
              option
            }
          }
        }
        subSectors {
          data {
            attributes {
              option
            }
          }
        }
        askingPrice {
          data {
            id
            attributes {
              option
            }
          }
        }
        margin {
          data {
            id
            attributes {
              option
            }
          }
        }
        country {
          data {
            attributes {
              option
            }
          }
        }
        desiredTransaction {
          data {
            attributes {
              option
            }
          }
        }
        numberOfEmployees {
          data {
            id
            attributes {
              option
            }
          }
        }
        idealBuyer {
          data {
            attributes {
              option
            }
          }
        }
        yearOfIncorporation
        targetExitPeriod {
          data {
            id
            attributes {
              option
            }
          }
        }
        aboutProperty
        businessDescription
        productsAndServices
        highlightsUniqueSellingPoint
        reasonForSale
        website
        mainMarket {
          data {
            attributes {
              option
            }
          }
        }
        users {
          data {
            id
            attributes {
              phone
              email
              username
            }
          }
        }
        tags
        externalId
        propertyFavorites(filters: {or: $sourceFilters}) {
          data {
            attributes {
              source
            }
          }
        }
      }
    }
  }
}
    `;
export function useSellerPropertyByIdQuery(baseOptions?: Apollo.QueryHookOptions<SellerPropertyByIdQuery, SellerPropertyByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SellerPropertyByIdQuery, SellerPropertyByIdQueryVariables>(SellerPropertyByIdDocument, options);
      }
export function useSellerPropertyByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SellerPropertyByIdQuery, SellerPropertyByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SellerPropertyByIdQuery, SellerPropertyByIdQueryVariables>(SellerPropertyByIdDocument, options);
        }
export function useSellerPropertyByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SellerPropertyByIdQuery, SellerPropertyByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SellerPropertyByIdQuery, SellerPropertyByIdQueryVariables>(SellerPropertyByIdDocument, options);
        }
export type SellerPropertyByIdQueryHookResult = ReturnType<typeof useSellerPropertyByIdQuery>;
export type SellerPropertyByIdLazyQueryHookResult = ReturnType<typeof useSellerPropertyByIdLazyQuery>;
export type SellerPropertyByIdSuspenseQueryHookResult = ReturnType<typeof useSellerPropertyByIdSuspenseQuery>;
export type SellerPropertyByIdQueryResult = Apollo.QueryResult<SellerPropertyByIdQuery, SellerPropertyByIdQueryVariables>;
export const SellerPropertyByNameDocument = gql`
    query sellerPropertyByName($name: String, $sourceFilters: [PropertyFavoriteFiltersInput]) {
  properties(filters: {companyName: {containsi: $name}}) {
    data {
      id
      attributes {
        companyName
        description
        confirmed
        annualRevenue {
          data {
            id
            attributes {
              option
            }
          }
        }
        historicalRevenueGrowth
        projectedRevenueGrowth
        profitability {
          data {
            attributes {
              option
            }
          }
        }
        image {
          data {
            attributes {
              url
            }
          }
        }
        market {
          data {
            attributes {
              url
            }
          }
        }
        industries {
          data {
            attributes {
              option
            }
          }
        }
        subSectors {
          data {
            attributes {
              option
            }
          }
        }
        askingPrice {
          data {
            id
            attributes {
              option
            }
          }
        }
        margin {
          data {
            id
            attributes {
              option
            }
          }
        }
        country {
          data {
            attributes {
              option
            }
          }
        }
        desiredTransaction {
          data {
            attributes {
              option
            }
          }
        }
        numberOfEmployees {
          data {
            id
            attributes {
              option
            }
          }
        }
        idealBuyer {
          data {
            attributes {
              option
            }
          }
        }
        yearOfIncorporation
        targetExitPeriod {
          data {
            id
            attributes {
              option
            }
          }
        }
        aboutProperty
        businessDescription
        productsAndServices
        highlightsUniqueSellingPoint
        reasonForSale
        website
        mainMarket {
          data {
            attributes {
              option
            }
          }
        }
        users {
          data {
            id
            attributes {
              phone
              email
              username
            }
          }
        }
        tags
        externalId
        propertyFavorites(filters: {or: $sourceFilters}) {
          data {
            attributes {
              source
            }
          }
        }
      }
    }
  }
}
    `;
export function useSellerPropertyByNameQuery(baseOptions?: Apollo.QueryHookOptions<SellerPropertyByNameQuery, SellerPropertyByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SellerPropertyByNameQuery, SellerPropertyByNameQueryVariables>(SellerPropertyByNameDocument, options);
      }
export function useSellerPropertyByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SellerPropertyByNameQuery, SellerPropertyByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SellerPropertyByNameQuery, SellerPropertyByNameQueryVariables>(SellerPropertyByNameDocument, options);
        }
export function useSellerPropertyByNameSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SellerPropertyByNameQuery, SellerPropertyByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SellerPropertyByNameQuery, SellerPropertyByNameQueryVariables>(SellerPropertyByNameDocument, options);
        }
export type SellerPropertyByNameQueryHookResult = ReturnType<typeof useSellerPropertyByNameQuery>;
export type SellerPropertyByNameLazyQueryHookResult = ReturnType<typeof useSellerPropertyByNameLazyQuery>;
export type SellerPropertyByNameSuspenseQueryHookResult = ReturnType<typeof useSellerPropertyByNameSuspenseQuery>;
export type SellerPropertyByNameQueryResult = Apollo.QueryResult<SellerPropertyByNameQuery, SellerPropertyByNameQueryVariables>;
export const TermsOfUseDocument = gql`
    query termsOfUse {
  termsOfUse {
    data {
      attributes {
        title
        content
      }
    }
  }
}
    `;
export function useTermsOfUseQuery(baseOptions?: Apollo.QueryHookOptions<TermsOfUseQuery, TermsOfUseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TermsOfUseQuery, TermsOfUseQueryVariables>(TermsOfUseDocument, options);
      }
export function useTermsOfUseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TermsOfUseQuery, TermsOfUseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TermsOfUseQuery, TermsOfUseQueryVariables>(TermsOfUseDocument, options);
        }
export function useTermsOfUseSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TermsOfUseQuery, TermsOfUseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TermsOfUseQuery, TermsOfUseQueryVariables>(TermsOfUseDocument, options);
        }
export type TermsOfUseQueryHookResult = ReturnType<typeof useTermsOfUseQuery>;
export type TermsOfUseLazyQueryHookResult = ReturnType<typeof useTermsOfUseLazyQuery>;
export type TermsOfUseSuspenseQueryHookResult = ReturnType<typeof useTermsOfUseSuspenseQuery>;
export type TermsOfUseQueryResult = Apollo.QueryResult<TermsOfUseQuery, TermsOfUseQueryVariables>;
export const GetUserByEmailDocument = gql`
    query GetUserByEmail($email: String!, $username: String) {
  usersPermissionsUsers(
    filters: {or: [{email: {eq: $email}}, {username: {eq: $username}}]}
  ) {
    data {
      attributes {
        id
        email
        username
        property {
          data {
            id
          }
        }
        propertyBuyer {
          data {
            id
          }
        }
      }
    }
  }
}
    `;
export function useGetUserByEmailQuery(baseOptions: Apollo.QueryHookOptions<GetUserByEmailQuery, GetUserByEmailQueryVariables> & ({ variables: GetUserByEmailQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserByEmailQuery, GetUserByEmailQueryVariables>(GetUserByEmailDocument, options);
      }
export function useGetUserByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByEmailQuery, GetUserByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserByEmailQuery, GetUserByEmailQueryVariables>(GetUserByEmailDocument, options);
        }
export function useGetUserByEmailSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserByEmailQuery, GetUserByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserByEmailQuery, GetUserByEmailQueryVariables>(GetUserByEmailDocument, options);
        }
export type GetUserByEmailQueryHookResult = ReturnType<typeof useGetUserByEmailQuery>;
export type GetUserByEmailLazyQueryHookResult = ReturnType<typeof useGetUserByEmailLazyQuery>;
export type GetUserByEmailSuspenseQueryHookResult = ReturnType<typeof useGetUserByEmailSuspenseQuery>;
export type GetUserByEmailQueryResult = Apollo.QueryResult<GetUserByEmailQuery, GetUserByEmailQueryVariables>;
export const GetUserByIdDocument = gql`
    query GetUserById($id: ID) {
  usersPermissionsUser(id: $id) {
    data {
      id
      attributes {
        propertyBuyer {
          data {
            attributes {
              companyName
              description
              confirmed
              annualRevenue
              industry {
                data {
                  attributes {
                    option
                  }
                }
              }
              country {
                data {
                  attributes {
                    option
                  }
                }
              }
              aboutProperty
              ticketSize {
                data {
                  attributes {
                    option
                  }
                }
              }
              revenueRange {
                data {
                  attributes {
                    option
                  }
                }
              }
              investmentStyle {
                data {
                  attributes {
                    option
                  }
                }
              }
              desiredEditda {
                data {
                  attributes {
                    option
                  }
                }
              }
              website
            }
          }
        }
        property {
          data {
            attributes {
              companyName
              description
              confirmed
              annualRevenue {
                data {
                  id
                  attributes {
                    option
                  }
                }
              }
              industries {
                data {
                  attributes {
                    option
                  }
                }
              }
              askingPrice {
                data {
                  id
                  attributes {
                    option
                  }
                }
              }
              margin {
                data {
                  id
                  attributes {
                    option
                  }
                }
              }
              country {
                data {
                  attributes {
                    option
                  }
                }
              }
              desiredTransaction {
                data {
                  attributes {
                    option
                  }
                }
              }
              numberOfEmployees {
                data {
                  id
                  attributes {
                    option
                  }
                }
              }
              idealBuyer {
                data {
                  attributes {
                    option
                  }
                }
              }
              yearOfIncorporation
              targetExitPeriod {
                data {
                  id
                  attributes {
                    option
                  }
                }
              }
              aboutProperty
              website
            }
          }
        }
        email
        phone
        username
      }
    }
  }
}
    `;
export function useGetUserByIdQuery(baseOptions?: Apollo.QueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
      }
export function useGetUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
        }
export function useGetUserByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
        }
export type GetUserByIdQueryHookResult = ReturnType<typeof useGetUserByIdQuery>;
export type GetUserByIdLazyQueryHookResult = ReturnType<typeof useGetUserByIdLazyQuery>;
export type GetUserByIdSuspenseQueryHookResult = ReturnType<typeof useGetUserByIdSuspenseQuery>;
export type GetUserByIdQueryResult = Apollo.QueryResult<GetUserByIdQuery, GetUserByIdQueryVariables>;